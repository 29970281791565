@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.insurance-terms-conditions-modal {
    display: flex;
    flex-direction: column;
    background: transparent;
    border-radius: 0.5rem;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow: hidden;

    .terms-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0.75rem 1rem;
        background: colors.$surface-200;

        h2 {
            border-bottom: none !important;
        }
    }

    .terms-body {
        max-height: 50vh;
        overflow-y: auto;
        padding: 1rem;
        margin: 1rem;

        h3 {
            @include fonts.h5;
            font-size: 1.125rem;
            margin-bottom: 1rem;
        }

        h4 {
            @include fonts.h6;
            margin: 1rem 0;
        }

        p {
            @include fonts.body3;
            color: colors.$text-500;
            line-height: 1.5rem;
            padding-bottom: 0.75rem;
        }

        .exclusions-list,
        .conditions-list {
            padding-left: 1.25rem;
            margin-top: 0.625rem;
            @include fonts.body3;
            color: colors.$text-500;
            line-height: 1.5rem;

            li {
                margin-bottom: 0.625rem;
            }
        }

        .terms-accept-checkbox {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            padding-top: 1rem;

            input[type=checkbox] {
                width: 1.5rem;
                height: 1.125rem;
            }
        }

        &::-webkit-scrollbar {
            width: 0.4rem;
        }

        &::-webkit-scrollbar-track {
            background: #cce5ff;
            border-radius: 0.25rem;
        }

        &::-webkit-scrollbar-thumb {
            background: #007bff;
            border-radius: 0.25rem;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #0056b3;
        }

    }

    .terms-footer {
        padding: 1rem;
        background: colors.$surface-200;

        .accept-btn {
            background: none;
            border: 1px solid #007bff;
            color: #007bff;
            padding: 0.625rem 1.25rem;
            border-radius: 5px;
            line-height: 1rem;
        }

        .accept-btn:disabled {
            cursor: not-allowed;
            background: none;
            opacity: 0.5;
        }

        .accept-btn:hover:not(:disabled) {
            background: #e6f0ff;
        }
    }

    @include screenSizes.tablet {
        .terms-header {
            padding: 0.5rem 1rem;
        }

        .terms-body {
            max-height: 35vh;

            h3 {
                @include fonts.h6;
                margin-bottom: 0.75rem;
            }

            h4 {
                @include fonts.h7;
                margin: 0.75rem 0;
            }

            p {
                line-height: 1.25rem;
            }

            .exclusions-list,
            .conditions-list {
                line-height: 1.25rem;
            }

            .terms-accept-checkbox {
                label {
                    @include fonts.body3;
                }

                input[type=checkbox] {
                    width: 1.5rem;
                    height: 1.125rem;
                }
            }
        }

        .terms-footer {
            .accept-btn {
                padding: 0.5rem 1rem;
                line-height: 0.875rem;
            }
        }
    }

    @include screenSizes.mobile {
        .terms-header {
            text-align: center;
            padding: 0.5rem 1rem;

            h2 {
                font-size: 1rem !important;
            }
        }

        .terms-body {
            max-height: 50vh;
            padding: 0.75rem;
            margin: 0.5rem;

            h3 {
                @include fonts.h7;
                margin-bottom: 0.5rem;
            }

            h4 {
                @include fonts.h7;
                font-size: 0.75rem;
                margin: 0.75rem 0;
            }

            p {
                @include fonts.body3;
                font-size: 0.75rem;
                padding-bottom: 0.5rem;
            }

            .exclusions-list,
            .conditions-list {
                padding-left: 1rem;
                margin-top: 0.5rem;
                @include fonts.body3;
                font-size: 0.75rem;

                li {
                    margin-bottom: 0.5rem;
                }
            }

            .terms-accept-checkbox {
                input[type=checkbox] {
                    width: 0.875rem;
                    height: 0.875rem;
                }

                label {
                    @include fonts.body3;
                }
            }
        }

        .terms-footer {
            justify-content: space-evenly;
            padding: 0.75rem;

            .accept-btn {
                padding: 0.5rem 1rem;
                line-height: 0.5rem;
            }
        }
    }
}