@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.insured-customers-report {
  .xs-insured-customers-table {
    display: none;
  }

  padding: 1rem;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 1rem;

    .search-stack {
      display: flex;
      flex-direction: row;
      align-items: center;

      .search {
        width: auto;
      }
    }

    .report-header {
      display: flex;
      align-items: center;
      @include fonts.subheader1-medium;
    }
  }

  .filter-row {
    padding: 1rem 0;
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    .button-row {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      width: 100%;
      align-items: center;

      .filters {
        display: flex;
        gap: 0.5rem;
        width: 100%;
        max-width: 30rem;

        .filter-category {
          width: inherit;
        }
      }

      .download-report {
        height: 2.25rem;
      }

      .date-input {
        width: 13rem;
      }

      .date-range-input {
        width: 17rem;

        .react-datepicker-wrapper {
          width: 100%;
        }
      }
    }
  }

  .header-hr {
    border: 1px solid colors.$surface-400;
    border-bottom: none;
  }

  .selected-filters-summary {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .filter-summary {
      display: flex;
      align-items: center;
      padding: 0.25rem 0.75rem;
      border-radius: 0.5rem;
      @include fonts.caption1;
      font-size: 0.75rem;
      border-color: colors.$filter-turquoise;
      color: colors.$filter-turquoise;
      background: colors.$powder-blue;
      border: 1px solid;

      .remove-filter-btn {
        display: flex;
      }

      button {
        background: none;
        border: none;
        margin-left: 0.5rem;
        cursor: pointer;

        svg {
          width: 0.75rem;
          height: 0.75rem;

          path {
            fill: colors.$filter-turquoise;
          }
        }
      }
    }
  }

  .insured-customer-details-modal {
    .card.inventory-action {
      padding: 2rem;
      margin: 0;
    }

    label {
      @include fonts.body1;
      overflow-wrap: break-word;
    }

    .close-button {
      margin: 1rem;
    }
  }

  @include screenSizes.tablet {
    .header {
      flex-direction: column;
      gap: 1rem;

      .report-header {
        @include fonts.subheader2-medium;
      }
    }

    .selected-filters-summary {
      margin-top: 0.75rem;

      .filter-summary {
        padding: 0.25rem;
        @include fonts.caption2;

        button {
          margin-left: 0.25rem;

          svg {
            width: 0.625rem;
            height: 0.625rem;
          }
        }
      }
    }

    .filter-row {
      .button-row {
        .filters {
          max-width: 25rem;

          .filter-dropdown {
            width: calc(100% - 1.5rem - 2px);

            .form-field {
              @include fonts.caption2;
            }

            .options {
              top: 2.5rem;
            }
          }

          .filter-category {
            width: inherit;
          }
        }

        .download-report {
          @include fonts.caption2;
        }

        .date-input {
          width: 9rem;

          input {
            @include fonts.caption2;
          }
        }

        .date-range-input {
          width: 13rem;

          input {
            width: calc(100% - 1.6rem - 2px);
            @include fonts.caption2;
          }
        }
      }
    }

    .insured-customer-details-modal {
      .card.inventory-action {
        padding: 1.5rem;
      }

      label {
        @include fonts.body2;
      }
    }
  }

  @include screenSizes.mobile {
    .desk-insured-customers-table {
      display: none;
    }

    padding: 1.5rem;

    .header {
      flex-direction: column;
      align-items: center;
      width: 100%;

      .report-header {
        @include fonts.h5;
        margin-bottom: 1rem;
      }

      .search-stack {
        width: 100%;

        .search {
          width: 100%;
        }
      }
    }

    .selected-filters-summary {
      margin-top: 0.75rem;

      .filter-summary {
        padding: 0.25rem;
        @include fonts.caption2;

        button {
          margin-left: 0.25rem;

          svg {
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }
    }

    .filter-row {
      .button-row {
        flex-direction: column;
        margin: 1rem 0rem;
        gap: 1rem;

        .filters {
          height: auto;
          justify-content: center;
          flex-wrap: wrap;

          .filter-category {
            width: inherit;
            flex: 1 1 49%;
          }
        }

        .download-report {
          @include fonts.caption2;
        }

        .date-input {
          width: inherit;
        }

        .date-range-input {
          width: inherit;

          .react-datepicker-wrapper {
            width: 100%;
          }
        }
      }
    }

    .insured-customer-details-modal {
      .card.inventory-action {
        padding: 1rem;

        h2 {
          @include fonts.h6;
          text-align: center
        }
      }

      label {
        @include fonts.body3;
        font-size: 0.75rem;
      }

      .flex-right {
        justify-content: center;
      }
    }

    .xs-insured-customers-table {
      display: block;

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0.5rem 0rem 0rem;
        }

        .insured-customers {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .insured-customers-content {
            flex: 3;
            border-right: 1px solid colors.$text-200;

            .card-content-section {
              display: flex;
              border-bottom: 1px solid colors.$text-200;
              padding: 0.5rem;

              .left {
                border-right: 1px solid colors.$text-200;
                padding-right: 0.5rem;
                flex: 1;
              }

              .right {
                padding-left: 0.5rem;
                flex: 1;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }

          .action-button {
            flex: 1;
            text-align: center;
          }

          label {
            @include fonts.caption1;
            font-size: 0.71rem;
          }
        }
      }

      .result-item {
        @include fonts.caption2;
        font-size: 0.75rem;
        display: block;
        text-align: center;
      }
    }
  }
}