@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.withdraw-shipping-preview {
    .shipping-preview-header {
        margin: 0.5rem 0;

        .review-purchase-title {
            padding: 0.5rem 0;
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    .form-row {
        padding: 0.15rem 0;

        label {
            font-size: 1rem;
        }
    }

    .animal-info,
    .buyer-info,
    .shipping-info,
    .order-summary {
        color: colors.$text-600;
        @include fonts.body2;

        h3 {
            @include fonts.h5;
            line-height: 2rem;
            border-bottom: 1px solid colors.$surface-600;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        .info-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        hr {
            margin: -0.25rem 0 0.25rem 0;
        }
    }

    .shipping-preview-buttons {
        margin: 1.5rem 0;

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 0.75rem;
        }
    }

    @include screenSizes.tablet {

        .animal-info,
        .buyer-info,
        .shipping-info,
        .order-summary {
            h3 {
                font-size: 1.125rem;
            }
        }
    }

    @include screenSizes.mobile {
        .shipping-preview-header {
            .review-purchase-title {
                font-size: 1.125rem;
            }

            label {
                font-weight: 400;
            }
        }

        .form-row {
            label {
                font-size: 0.75rem;
                overflow-wrap: break-word
            }
        }

        .animal-info,
        .buyer-info,
        .shipping-info,
        .order-summary {
            h3 {
                @include fonts.h6;

                .icon {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }

        .animal-info .xs-select-specimens .selected-specimens-row .select-specimens {
            border-left: none;
        }

        .shipping-preview-buttons {
            .buttons {
                flex-direction: column;
                align-items: end;
            }
        }
    }
}

.step-counter {
    display: flex;
    align-items: center;
    @include fonts.body3-semibold;
    margin-bottom: 1rem;

    .step-connector {
        width: 2.625rem;
        height: 0.2rem;
        background-color: colors.$primary-green-500;
    }

    .step-bubble {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.75rem;
        height: 1.75rem;
        border: 2px solid colors.$primary-green-500;
        border-radius: 10rem;

        &.active {
            width: 2rem;
            height: 2rem;
            background-color: colors.$primary-green-500;
        }
    }

    @include screenSizes.mobile {
        font-size: 0.75rem;
        margin: 1rem 0rem;

        .step-connector {
            width: 2.625rem;
            height: 0.1rem;
        }

        .step-bubble {
            width: 1.25rem;
            height: 1.25rem;

            &.active {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}