@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.add-payment-method {
  padding: 2rem;

  .header h4 {
    @include fonts.h4;
    margin-bottom: 1.5rem;
  }

  .payment-method {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    label {
      @include fonts.body2;
    }

    .card-details-note {
      @include fonts.body2;
    }
  }

  .input {
    margin-top: 1rem;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .input {
      width: calc(50% - 0.5rem);
    }
  }

  .billing-address,
  .payment-details {
    margin-top: 1.5rem;

    h3 {
      margin-bottom: 1.5rem;
      border-bottom: 1px solid colors.$surface-500;
    }
  }

  .set-as-default-condition {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding-top: 1rem;

    p {
      @include fonts.body3;
      color: colors.$text-600;
    }

    b {
      @include fonts.body3-semibold;
      text-decoration: underline;
    }

    input {
      width: 1.15rem;
      height: 1.15rem;
    }
  }

  .payment-transaction-note {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .transaction-alert {
      display: flex;
      align-items: center;
      color: colors.$secondary-red-300;
      gap: 0.5rem;
      padding-bottom: 0.5rem;
      @include fonts.caption2;
      font-size: 0.75rem;
    }

    .outstanding-balance-note {
      p {
        @include fonts.caption1;
        margin: 0.5rem 0rem;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 1.5rem 0rem 1.5rem 1.5rem;
    gap: 3rem;

    button {
      padding: 0.7rem;
    }
  }

  @include screenSizes.mobile {
    .header {
      text-align: center;
    }

    .payment-method {
      label {
        @include fonts.body3;
      }

      .card-details-note {
        @include fonts.body3;
      }
    }

    input[type=radio] {
      width: 1rem;
      height: 1rem;
    }

    .billing-address {
      h5 {
        @include fonts.caption1-semibold;
      }
    }

    .payment-transaction-note .transaction-alert p,
    .validated-input-label {
      @include fonts.caption1;
      font-size: 0.75rem;
    }

    .set-as-default-condition {
      p {
        font-size: 0.75rem;
      }

      input {
        width: 1rem;
        height: 1rem;
      }
    }

    .payment-transaction-note .outstanding-balance-note {
      p {
        font-size: 0.75rem;
      }
    }

    .footer {
      justify-content: center;
      padding: 2rem 1.5rem 0rem 1.5rem;

      .button {
        width: 8rem;
      }
    }
  }
}