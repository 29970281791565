@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.shipping-payment-information {
    padding: 0;

    .payment-info-header {
        margin: 0.5rem 0;
    }

    .payment-info-subheader {
        margin: 1.5rem 0;
    }

    .ach-warning {
        display: flex;
        gap: 0.5rem;

        svg {
            padding-top: 0.2rem;
            width: 2rem;
        }

        label {
            color: colors.$secondary-red-300;
        }
    }

    .form-row {
        padding: 0.15rem 0;

        label {
            font-size: 1rem;
        }
    }

    .payment-method-selection {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        label {
            @include fonts.body2;
        }
    }

    .input {
        width: 75%;
    }

    .input-row {
        width: 75%;
    }

    .mark-as-paid {
        margin: 2rem 0;

        .mark-as-paid-question {
            @include fonts.body1-semibold;
        }

        .mark-as-paid-info {
            margin-top: 1rem;
            margin-bottom: 2rem;
            color: colors.$text-600;

            ul {
                margin-block: 0.25rem;
                @include fonts.body2;
            }
        }

        .expanded-text-area label {
            @include fonts.body1;
            color: colors.$text-600;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 0.75rem;
        padding: 1rem 0.5rem;
    }

    @include screenSizes.tablet {

        .payment-details,
        .billing-address {
            h3 {
                font-size: 1.125rem;
            }
        }

        .mark-as-paid {
            .mark-as-paid-question {
                @include fonts.body2-semibold;
            }

            .expanded-text-area label {
                @include fonts.body2;
            }
        }
    }

    @include screenSizes.mobile {
        .payment-info-header h2 {
            font-size: 1rem !important;
        }

        .payment-info-subheader {
            label {
                font-weight: 400;
            }
        }

        .payment-method-selection {
            label {
                @include fonts.body3;
            }
        }

        .payment-details,
        .billing-address {
            h3 {
                font-size: 1rem;
            }
        }

        .mark-as-paid {

            .mark-as-paid-question {
                @include fonts.body3-semibold;
            }

            .mark-as-paid-info {
                @include fonts.body3;

                ul {
                    @include fonts.body3;
                }
            }

            .expanded-text-area label {
                @include fonts.body2;
            }
        }
    }
}